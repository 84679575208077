import React, { useContext, useState, useEffect } from 'react';
import ErrorHandler from './../errorHandler/ErrorHandler';
import ChartHeader from './chartHeader/ChartHeader';
import Timeline from './timeline/Timeline';
import WarningMessages from './warningMessages/WarningMessages';
import './Chart.css'
import {simulatedSupport, loadData, calculatePercentage, getColorForEol } from '../../utils';
import { defaultSimulatedSupportYears } from './../../constants'
import ChartContext from '../../context/chartContextProvider/chartContextProvider';
import GlobalContext from '../../context/globalContext';

const Chart = ({propSoftware, propRequestedVersion}) => {
  const { setSoftware, simulatedSupportYears, setSimulatedSupportYears, selectedVersion, setSelectedVersion } = useContext(ChartContext);
  const { today } = useContext(GlobalContext);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // Call the API
  useEffect(() => {
    loadData(propSoftware, setData, setError)
  }, [propSoftware]);

  // Set the contexts
  useEffect(()=> {
    setSoftware(propSoftware)
    setSimulatedSupportYears(defaultSimulatedSupportYears[propSoftware])
    setSelectedVersion(propRequestedVersion)
  }, [propSoftware, setSoftware, propRequestedVersion, setSelectedVersion, setSimulatedSupportYears])

  // Catching errors
  if (error || !data) {
    return <ErrorHandler error={error} data={data}/>;
  }

  // Filter out releases based on user selection. 
  const filteredData = data.filter(release => {
    if (selectedVersion === 'older') {
      return data
    }
    const releaseDate = new Date(release.releaseDate);
    // default to earliest possible date if no version selected
    const selectedReleaseDate = selectedVersion ? new Date(data.find(r => r.cycle === selectedVersion).releaseDate) : new Date(0); 

    return releaseDate >= selectedReleaseDate;
  });

  // Calculate the start date for the timeline based on the earliest release date.
  const startDate = filteredData.reduce((earliestDate, item) => {
    const currentReleaseDate = new Date(item.releaseDate);
    return currentReleaseDate < earliestDate ? currentReleaseDate : earliestDate;
  }, new Date(today));

  // Step 1: Extract all real eol values, filter out the falsy values
  const eolValues = filteredData
    .map(item => new Date(item.eol).getTime()) // Convert eol to timestamp for easier comparison
    .filter(Boolean); // Remove falsy values

  // Step 2: Extract all simulated eol values
  const simulatedEolValues = filteredData
    .map(item => {
      if (!item.eol) { // If real eol is falsy, compute simulated EOL
        return simulatedSupport(item.releaseDate, simulatedSupportYears);
      }
      return null; // Return null otherwise
    })
    .filter(date => date !== null); // Filter out non-simulated EOLs


// Step 3: Determine the maximum EOL for both real and simulated
const maxRealEol = eolValues.length > 0 ? Math.max(...eolValues) : 0;
const maxSimulatedEol = simulatedEolValues.length > 0 ? Math.max(...simulatedEolValues) : 0;

// Step 4: Compare the max real EOL with max simulated EOL
const endDate = new Date(Math.max(maxRealEol, maxSimulatedEol));


  // cycle: Software version (ex: 3.2, 3.1 or 3.0)
  // start: in % -> This will determine how left in the chart we put the event
  // end: in % -> This will determine how far to the right the event will stretch
  // color: function determines color of the event
  // className: Highlighter class applied if software version is selected
  // releaseDate: Release date of the software version
  // EOLDate: End of life date of the software version
  // lts: Boolean indicating if the software version is a LTS version
  const events = filteredData.map(release => ({
    label: release.cycle,
    start: calculatePercentage(release.releaseDate, startDate, endDate),
    end: calculatePercentage(release.eol, startDate, endDate),
    color: getColorForEol(release.eol, simulatedSupport(release.releaseDate, simulatedSupportYears), today, release.lts),
    className: release.cycle === selectedVersion ? 'highlighted' : '',
    releaseDate: release.releaseDate,
    EOLDate: release.eol,
    lts: release.lts
  }));

  return(<div className='timeline-wrapper'>
          <ChartHeader data={data} filteredData={filteredData}/>
          <Timeline events={events} startDate={startDate} endDate={endDate} selectedVersion={selectedVersion}/>
          <WarningMessages data={data}/>
      </div>
  );
};

export default Chart;