import React from 'react';

const FoxsoftLogo = () => {

  return (<>
            <div className='foxsoft-logo'>
              <svg width="100%" height="100%" viewBox="0 0 352 297" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                  <g transform="matrix(4.16667,0,0,4.16667,0,-3.39168)">
                      <path d="M1.901,0.814L6.655,0.814C17.547,2.123 24.635,7.237 29.355,14.719C24.66,11.965 19.823,9.355 12.121,9.609C11.484,17.251 12.46,26.506 9.625,31.951C7.053,21.172 5.266,9.611 0,1.528L0,1.17C0.59,1.01 1.344,1.01 1.901,0.814Z" fill="rgb(64,122,188)"/>
                  </g>
                  <g transform="matrix(4.16667,0,0,4.16667,0,-3.39168)">
                      <path d="M77.484,0.814L82.238,0.814C82.823,1.101 83.932,0.864 84.259,1.409C80.386,7.518 78.057,15.589 76.416,23.87C75.877,26.584 75.494,29.455 74.395,31.951C71.966,26.18 72.453,17.494 72.137,9.609C64.366,9.324 59.539,11.985 54.785,14.719C59.538,7.27 66.604,2.135 77.484,0.814Z" fill="rgb(64,122,188)"/>
                  </g>
                  <g transform="matrix(4.16667,0,0,4.16667,0,-3.39168)">
                      <path d="M67.621,24.227C66.035,26.046 64.36,27.779 62.868,29.692C57.914,25.989 52.46,21.125 44.446,20.542C34.386,19.81 28.533,24.934 23.412,29.812C21.523,27.936 19.581,26.114 17.945,23.989C23.708,18.262 31.965,14.938 41.951,13.53C46.781,12.85 52.868,15.124 56.925,16.977C61.134,18.898 64.992,21.408 67.621,24.227Z" fill="rgb(64,122,188)"/>
                  </g>
                  <g transform="matrix(4.16667,0,0,4.16667,0,-3.39168)">
                      <path d="M17.945,37.3C17.438,38.617 16.084,39.919 15.806,41.696C15.416,44.174 16.092,46.87 15.806,49.659C17.129,49.896 18.955,49.348 20.44,49.659C21.274,49.833 22.382,50.809 23.412,51.44C26.266,53.193 29.549,54.815 31.73,57.027C34.683,60.019 36.621,64.046 36.959,68.911C36.305,69.069 35.71,68.145 35.058,67.605C31.794,64.901 28.21,62.257 24.481,59.76C23.043,58.798 21.342,57.626 19.846,57.146C16.749,56.153 12.509,57.027 8.794,57.027C8.676,52.494 8.007,48.055 8.319,43.954C8.63,39.843 10.814,35.745 12.596,33.733C14.63,34.671 16.727,35.545 17.945,37.3Z" fill="rgb(64,122,188)"/>
                  </g>
                  <g transform="matrix(4.16667,0,0,4.16667,0,-3.39168)">
                      <path d="M76.416,57.027C72.869,56.992 68.898,56.257 65.839,57.027C64.268,57.421 62.463,58.639 60.965,59.641C56.587,62.573 52.231,66.072 48.25,69.149C48.848,57.784 57.279,54.251 64.65,49.659L69.403,49.659C69.145,46.912 69.755,44.141 69.403,41.696C69.127,39.775 67.729,38.558 67.383,37.3C68.463,35.487 70.971,34.47 72.731,33.733C77.373,38.292 77.426,47.969 76.416,57.027Z" fill="rgb(64,122,188)"/>
                  </g>
                  <g transform="matrix(4.16667,0,0,4.16667,0,-3.39168)">
                      <path d="M37.672,37.3L37.672,48.352C32.22,44.89 26.843,41.355 21.391,37.893C26.556,37.434 32.293,37.544 37.672,37.3Z" fill="rgb(64,122,188)"/>
                  </g>
                  <g transform="matrix(4.16667,0,0,4.16667,0,-3.39168)">
                      <path d="M64.174,37.774C58.897,41.331 53.175,45.076 47.893,48.232L47.893,37.3C53.311,37.467 58.779,37.584 64.174,37.774Z" fill="rgb(64,122,188)"/>
                  </g>
                  <g transform="matrix(4.16667,0,0,4.16667,0,-3.39168)">
                      <path d="M44.328,72L41.357,72C40.183,71.709 38.782,71.644 37.91,71.05C39.524,68.425 41.022,65.685 42.901,63.325C44.408,66.019 46.171,68.454 47.656,71.169C46.653,71.554 45.476,71.763 44.328,72Z" fill="rgb(64,122,188)"/>
                  </g>
              </svg>
            </div>
          </>
  );
}

export default FoxsoftLogo;


