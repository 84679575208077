import axios from 'axios';

const simpleFetchApiData = (software) => {
  return axios.get(`https://endoflife.date/api/${software}.json`, {
    headers: {
      'Accept': 'application/json'
    }
  })
  .then(response => response.data)
  .catch(err => {
    console.error('Error fetching data:', err);
    throw err;
  });
};

export default simpleFetchApiData