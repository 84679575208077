import React, { useContext } from 'react';
import { computePosition } from './../../../../utils';
import GlobalContext from '../../../../context/globalContext';
import './TodayLine.css'

function TodayLine({startDate, totalDuration}) {
  const { today } = useContext(GlobalContext);
  const todayPosition = computePosition(new Date(today), startDate, totalDuration);

  return (<>
            <div className="timeline-today-line" style={{ left: `${todayPosition}%` }}></div>
          </>
  );
}

export default TodayLine;
