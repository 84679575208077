import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Charts from './packs/charts/Charts';
import LandingPage from './packs/landingPage/LandingPage';
import HomeButton from './packs/homeButton/HomeButton.js';
import FloatingIcon from './packs/floatingIcon/FloatingIcon';
import { GlobalProvider } from './context/globalContext'
import './App.css'

function App() {

  return (<>
          <GlobalProvider>
          <HomeButton/>
            <div className="App">
              <Routes>
                <Route path="/" exact element={<LandingPage/>} />
                <Route path="/charts" element={<Charts/>} />
              </Routes>
            </div>
            <FloatingIcon/>
          </GlobalProvider>
          </>
  );
}

export default App;
