const defaultSimulatedSupportYears = 
{ 
// ----- Foxsoft Stack -----
  ruby: 3,
  rails: 4,
  postgresql: 6,
  mysql: 8, 
  debian: 3,
  ubuntu: 3,
// --------------------------
  alpine: 3,
  'amazon-linux': 3,
  android: 3,
  bootstrap: 3,
  centos: 3,
  django: 3,
  dotnet: 3,
  dotnetfx: 3,
  drupal: 3,
  elasticsearch: 3,
  elixir: 3,
  fedora: 3,
  filemaker: 3,
  freebsd: 3,
  go: 1,
  godot: 3,
  iphone: 3,
  java: 3,
  kindle: 3,
  kubernetes: 3,
  laravel: 3,
  macos: 3,
  magento: 3,
  mariadb: 3,
  mongodb: 3,
  mssqlserver: 3,
  nodejs: 3,
  office: 3,
  opensuse: 3,
  perl: 3,
  php: 3,
  pixel: 3,
  powershell: 3,
  python: 3,
  qt: 3,
  rabbitmq: 3,
  redis: 3,
  rhel: 3,
  ros: 3,
  sles: 3,
  'spring-framework': 3,
  surface: 3,
  symfony: 3,
  vue: 3,
  wagtail: 3,
  windows: 3,
  "rails-lts": 3,
}

export default defaultSimulatedSupportYears