import React from 'react';
import EventsMapper from './eventsMapper/EventsMapper';
import YearsMapper from './yearsMapper/YearsMapper';
import TodayLine from './todayLine/TodayLine';
import './Timeline.css';

const Timeline = ({ events, startDate, endDate }) => {
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();
  const totalDuration = endDate - startDate;

  return (
    <div className="timeline-container">
      <EventsMapper events={events}/>
      <YearsMapper startDate={startDate} startYear={startYear} endYear={endYear} totalDuration={totalDuration}/>
      <TodayLine startDate={startDate} totalDuration={totalDuration} />
    </div>
  );
};

export default Timeline;
