 const supportedSoftwares = [
  "alpine",
  "amazon-linux",
  "android",
  "bootstrap",
  "centos",
  "debian",
  "django",
  "dotnet",
  "dotnetfx",
  "drupal",
  "elasticsearch",
  "elixir",
  "fedora",
  "filemaker",
  "freebsd",
  "go",
  "godot",
  "heroku",
  "iphone",
  "java",
  "kindle",
  "kubernetes",
  "laravel",
  "rails-lts",
  "macos",
  "magento",
  "mariadb",
  "mongodb",
  "mssqlserver",
  "mysql",
  "nodejs",
  "office",
  "opensuse",
  "perl",
  "php",
  "pixel",
  "postgresql",
  "powershell",
  "python",
  "qt",
  "rabbitmq",
  "rails",
  "redis",
  "rhel",
  "ros",
  "ruby",
  "sles",
  "spring-framework",
  "surface",
  "symfony",
  "ubuntu",
  "vue",
  "wagtail",
  "windows",
]

export default supportedSoftwares