import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Image from './../../imgs/Foxsoft.js'
import GlobalContext from './../../context/globalContext.js'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './HomeButton.css'

function HomeButton() {
  const { today, setToday } = useContext(GlobalContext);

  function handleDateChange(date) {
    const selectedDate = date.toISOString().split('T')[0];
    setToday(selectedDate);
    
    // Update the URL with the selected date
    const url = new URL(window.location);
    url.searchParams.set('date', selectedDate);
    window.history.pushState({}, '', url);
  }

  const handleTodayClick = () => {
    const currentDate = new Date();

    const url = new URL(window.location);
    url.searchParams.delete('date');  // Removes the 'date' parameter from the URL
    window.history.pushState({}, '', url);

    setToday(currentDate.toISOString().split('T')[0]);
};

  // Function to set the 'today' state to the current date
  const setTodayToCurrent = () => {
    setToday(new Date());
  }

  return (<div className="home-button">
          <Link to="/" onClick={setTodayToCurrent}>
            <Image/>
          </Link>
            <div className="date-picker-wrapper">
            <DatePicker
                  selected={new Date(today)}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  id="datePicker"
                  className="form-control"
              />
              <div className='button-wrapper'>
                <button onClick={handleTodayClick} className="btn mx-2">Today</button>
              </div>
            </div>
          </div>
  )}

export default HomeButton;
