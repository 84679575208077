import axios from 'axios';

const fetchApiData = (software, setData, setError) => {
  axios.get(`https://endoflife.date/api/${software}.json`, {
    headers: {
      'Accept': 'application/json'
    }
  })
  .then(response => {
    const fetchedData = response.data;
    
    // Save data and timestamp in localStorage
    const key = `${software}-timestamp`

    localStorage.setItem(software, JSON.stringify(fetchedData));
    localStorage.setItem(key, Date.now().toString());

    setData(fetchedData);
  })
  .catch(err => {
    console.error('Error fetching data:', err);
    setError(err);
  });
};

export default fetchApiData;