import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlDate = queryParams.get('date');
  
  const defaultDate = urlDate ? new Date(urlDate) : new Date();
  
  const [today, setToday] = useState(defaultDate);

  return (
    <GlobalContext.Provider 
    value={{ today, setToday }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;