import React from 'react';

function ErrorHandler({error, data}) {

  return (
    <>
      {error && <div>Error: {error.message}</div>}
      {!error && !data && <div>Loading...</div>}
    </>
  );
}

export default ErrorHandler;