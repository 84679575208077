import React, { createContext, useState } from 'react';

const ChartContext = createContext();

export const ChartProvider = ({ children }) => {
  const [software, setSoftware] = useState(null);
  const [simulatedSupportYears, setSimulatedSupportYears] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState('');
  const [daysSinceSupport, setDaysSinceSupport] = useState('');
  

  return (
    <ChartContext.Provider 
    value={{ software, 
             setSoftware, 
             simulatedSupportYears, 
             setSimulatedSupportYears, 
             selectedVersion,  
             setSelectedVersion,
             daysSinceSupport, 
             setDaysSinceSupport
             }}>
      {children}
    </ChartContext.Provider>
  );
};

export default ChartContext;