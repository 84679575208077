import React, { useContext } from 'react';
import { handleSelectionChange } from '../../../../utils';
import ChartContext from '../../../../context/chartContextProvider/chartContextProvider';

function VersionSelector({data}) {
  const { software, selectedVersion, setSelectedVersion,} = useContext(ChartContext);

  return (<>
            <select className='form-control mb-2'
              value={selectedVersion}
              onChange={(e) => {handleSelectionChange(e, setSelectedVersion, software)}}>
              <option value=''>version</option>
              {data.map(release => (
                <option key={release.cycle} value={release.cycle}>
                    {release.cycle}
                </option>
              ))
              }
              <option value='older'>older</option>
            </select>
          </>
  );
}

export default VersionSelector;
