import fetchApiData from "./fetchApiData";
import loadStaticData from "./loadStaticData";

const loadData = async (software, setData, setError) => {
  // First, try to load static data
  const staticData = await loadStaticData(software);

  if (staticData) {
    console.log(`Static data used for ${software}`);
    setData(staticData);
    return;
  }

  // If no static data, check local storage
  const cachedData = localStorage.getItem(software);
  const dataTimestamp = localStorage.getItem(`${software}-timestamp`);

  if (cachedData && dataTimestamp) {
    const currentTime = Date.now();
    const timeDifference = currentTime - parseInt(dataTimestamp);
    const oneHour = 60 * 60 * 1000; // In milliseconds

    // Use cached data if it is fresh (less than 1 hour old)
    if (timeDifference <= oneHour) {
      console.log(`Cached data used for ${software}`);
      setData(JSON.parse(cachedData));
      return;
    }
  }

  // If no static or cached data, fetch from API
  fetchApiData(software, setData, setError);
};

export default loadData;
