const getColorForEol = (incomingEol, startDate, today, lts) => {
  if (lts && !incomingEol) {
    return 'green'
  }
  else {

    const eolDate = incomingEol ? new Date(incomingEol) : startDate
    const currentDate = new Date(today);
    const oneYearFromNow = new Date(today);
    oneYearFromNow.setFullYear(currentDate.getFullYear() + 1);
    const classes = []

    if (!incomingEol) {
      classes.push('simulated-support');
    }

    if (eolDate <= currentDate) {
      classes.push('red'); // EOL is in the past
    } else if (eolDate <= oneYearFromNow) {
      classes.push('yellow'); // EOL is within one year
    } else if (eolDate >= currentDate) {
      classes.push('green'); // EOL is more than one year away
    } 

    return classes.join(' ')
  }
};

export default getColorForEol;