import React from 'react';
import Chart from '../chart/Chart';
import { supportedSoftwares } from '../../constants';
import { ChartProvider } from '../../context/chartContextProvider/chartContextProvider';
import './Charts.css'

function Charts() {
  const urlParams = new URLSearchParams(window.location.search);
  const softwaresFromUrl = [...urlParams.keys()];
  const requestedSoftwares = softwaresFromUrl[0] === undefined || softwaresFromUrl[0] ===  'date' ? supportedSoftwares : softwaresFromUrl.filter(key => supportedSoftwares.includes(key));  

  return (
    <div className="flex-container">
      {requestedSoftwares.map((software, index) => {
        let requestedVersion = urlParams.get(software);

        return (
          <div className='container-wrapper' key={index}>
            <ChartProvider> 
              <Chart propSoftware={software} propRequestedVersion={requestedVersion} key={index}/>
            </ChartProvider>
          </div>
        )}
        )}
    </div>
  );
}

export default Charts;
