import simulatedSupport from "./simulateSupport";

export const isVersionUnsupported = (version, allData, simulatedSupportYears, today) => {
  if (!version || version === 'older') { return }
  
  const currentDate = new Date(today);
  const selectedVersionData = allData.find(data => data.cycle === version);
  const eolDate = new Date((selectedVersionData.eol || simulatedSupport(selectedVersionData.releaseDate, simulatedSupportYears)));
  return selectedVersionData && eolDate <= currentDate;
};


export const isVersionDeprecated = (version, allData, simulatedSupportYears, today) => {
  if (!version || version === 'older') 
  { return } 
  else if (isVersionUnsupported(version, allData))
  { return false }

  const currentDate = new Date(today);
  const oneYearFromNow = new Date(today);
  oneYearFromNow.setFullYear(currentDate.getFullYear() + 1);

  const selectedVersionData = allData.find(data => data.cycle === version);

  const eolDate = new Date((selectedVersionData.eol || simulatedSupport(selectedVersionData.releaseDate, simulatedSupportYears)));
  return selectedVersionData && eolDate <= oneYearFromNow;
};