import React from 'react';
import './EventsMapper.css'

function EventsMapper({events}) {

  function formatDate(dateString) {
    // Check if dateString is not a valid date
    if (!dateString || isNaN(Date.parse(dateString))) {
      return "N/A";
    }

    // Split the date string into components
    let parts = dateString.split('-');

    // Create a new Date object
    let date = new Date(parts[0], parts[1] - 1, parts[2]);

    // Format the date
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
}


  return (<>
          {events.map((event, index) => (
            <div key={index} className="event-row">
              <div className={`event-line ${event.className}`}></div>
              <div className={`event ${event.className} ${event.color}`} style={{
                left: `${event.start}%`,
                width: `${event.end - event.start}%`
              }}
              title={`Released: ${formatDate(event.releaseDate)} \nEOL: ${event.EOLDate ? formatDate(event.EOLDate) : event.lts ? 'No date published':  'Unkown'  }`}
              >
                <div className='mx-2'>
                  {event.label}
                </div>
              </div>
            </div>
          ))}
          </>
  );
}

export default EventsMapper;
