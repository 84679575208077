import React, { useContext } from 'react';
import { isVersionDeprecated, isVersionUnsupported, capitalize, simulatedSupport } from './../../../utils'
import ChartContext from '../../../context/chartContextProvider/chartContextProvider';
import GlobalContext from '../../../context/globalContext';
import './WarningMessages.css'

function WarningMessages({data}) {
  const { software, simulatedSupportYears, selectedVersion } = useContext(ChartContext);
  const { today: todayString } = useContext(GlobalContext);

  let today = new Date();
  let dateFromTodayString = new Date(todayString);
  const versionData = data.find(item => item.cycle === selectedVersion);


  let isTodaySimulatedMessage = (today.toDateString() !== dateFromTodayString.toDateString()) ? `(As of ${dateFromTodayString.toDateString()})` : '';

  function daysUntilEOL(selectedVersion, versionData) {
    if (!selectedVersion || selectedVersion === 'older') {
      return { days: 0, isSimulated: false };
    }
    
    let eolDate;
    let isEOLSimulated = false;

    if (versionData.eol) {
      eolDate = new Date(versionData.eol);
    } else {
      eolDate = new Date(simulatedSupport(versionData.releaseDate, simulatedSupportYears));
      isEOLSimulated = true;  // Mark this as a simulated date
    }
    
    eolDate.setHours(0, 0, 0, 0);
    
    const today = new Date(todayString);
    today.setHours(0, 0, 0, 0);
    
    let diffDays = (eolDate - today) / (1000 * 60 * 60 * 24);
    diffDays = Math.abs(diffDays);
    
    return {
      days: Math.round(diffDays),
      isSimulated: isEOLSimulated
    };
  }

  const { days: daysRemaining, isSimulated } = daysUntilEOL(selectedVersion, versionData);

  return (
    <>
      {(() => {

        if (versionData && versionData.lts && !versionData.eol) {
          return (
            <div className="lts-message">
              {capitalize(software)} {selectedVersion} is a Long Term Support (LTS) version and has no end of life date published
            </div>
          );
        }

        if (isVersionUnsupported(selectedVersion, data, simulatedSupportYears, new Date(todayString))) {

          if (isSimulated) {
            return (
            <div className="expired-message">
              {capitalize(software)} {selectedVersion} has no fixed end of life, but based on trends, it could have been deprecated <strong>~{daysRemaining}</strong> days ago. {isTodaySimulatedMessage}
            </div>
            );
          }
          else {
            return (
              <div className="expired-message">
                {capitalize(software)} {selectedVersion} has not been actively supported for <strong>{daysRemaining}</strong> days. {isTodaySimulatedMessage}
              </div>
          );
        }

        } else if (isVersionDeprecated(selectedVersion, data, simulatedSupportYears, new Date(todayString))) {

          if (isSimulated) {
            return (
              <div className="deprecated-message">
                {capitalize(software)} {selectedVersion} has no fixed end of life, but based on trends, it could be deprecated in <strong>~{daysRemaining}</strong> days. {isTodaySimulatedMessage}
              </div>
            );
          } else {
            return (
              <div className="deprecated-message">
                {capitalize(software)} {selectedVersion} is about to be deprecated in <strong>{daysRemaining}</strong> days. {isTodaySimulatedMessage}
              </div>
            );
          }

        } else if (selectedVersion === 'older') {

          return (
            <div className="expired-message">
              This version is so old it's off the charts!
            </div>
          );

        } else if (selectedVersion){
          if (isSimulated) {
            return (
              <div className="covered-message">
              {capitalize(software)} {selectedVersion} is covered and has no fixed end of life, based on trends, it could be deprecated in <strong>~{daysRemaining}</strong> days. {isTodaySimulatedMessage}
              </div>
             );} 
             else{
          return (
            <div className="covered-message">
              {capitalize(software)} {selectedVersion} is covered for the next <strong>{daysRemaining}</strong> days. {isTodaySimulatedMessage}
            </div>
          );
        }
      }
      })()}
    </>
  );
}


export default WarningMessages;
