import React from 'react';
import { computePosition } from './../../../../utils';
import './YearsMapper.css'

function YearsMapper({startDate, startYear, endYear, totalDuration}) {
  // Generate an array of years between startYear and endYear
  const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

  // Generate abbreciated years from 
  const abrbeviatedYears = years.map(year => parseInt(String(year).slice(-2)));

  return (<>
          <div className="years-container">
            {years.map((year, index) => {

              let positionDate;

              // Use January 1st for all other years
              positionDate = new Date(`${year}-01-01`);
              
              return (
                <div 
                  key={year} 
                  style={{ left: `${computePosition(positionDate, startDate, totalDuration)}%`, position: 'absolute'}}>
                  <span className="year-line">|</span>
                  <span className="year-label">'{abrbeviatedYears[index]}</span>
                </div>
              );
            })}
          </div>
          </>
  );
}

export default YearsMapper;
