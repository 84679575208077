import React from 'react';
import './FloatingIcon.css';

function FloatingIcon() {
    const url = "https://app.tettra.co/teams/foxsoft/pages/lemming"

    return (
        <a href={url} target="_blank" rel="noopener noreferrer" className="floating-icon">
          <img src="https://cdn-icons-png.flaticon.com/512/545/545674.png" alt="" />
        </a>
    );
}

export default FloatingIcon;