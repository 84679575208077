import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { simpleFetchApiData } from '../../utils';
import { landingPageSupportedTechs } from '../../constants';
import { loadStaticData } from '../../utils';
import './LandingPage.css'

function LandingPage() {
  const navigate = useNavigate();
  
  // Store software versions in an object where each key is a software name and its value is an array of version objects.
  const [techData, setTechData] = useState({});
  const [error, setError] = useState(null);
  
  const selectRefs = useRef({});

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the base URL
    const url = new URL(window.location);

    // If there's a date query param, remove it temporarily
    const dateParam = url.searchParams.get('date');
    if (dateParam) {
        url.searchParams.delete('date');
    }

    let allEmpty = true;
    for (const tech of landingPageSupportedTechs) {
        const selectedVersion = selectRefs.current[tech].value;

        // Check if any version has been selected
        if (selectedVersion) {
            allEmpty = false;
            url.searchParams.append(tech, selectedVersion);
        }
    }

    // If all dropdowns are empty, just append the tech names
    if (allEmpty) {
        for (const tech of landingPageSupportedTechs) {
            url.searchParams.append(tech, '');
        }
    }

    // Add the date query param back (at the end)
    if (dateParam) {
        url.searchParams.append('date', dateParam);
    }

    navigate(`/charts${url.pathname + url.search}`);
  };

  useEffect(() => {
    landingPageSupportedTechs.forEach(async (software) => {
      const staticData = await loadStaticData(software);

      if (staticData) {
        setTechData(prev => ({ ...prev, [software]: staticData }));
      } else {
        const cachedData = localStorage.getItem(software);
        const dataTimestamp = localStorage.getItem(`${software}-timestamp`);
        const currentTime = Date.now();
        const oneHour = 60 * 60 * 1000;

        if (cachedData && dataTimestamp && (currentTime - parseInt(dataTimestamp)) <= oneHour) {
          const parsedData = JSON.parse(cachedData);
          setTechData(prev => ({ ...prev, [software]: parsedData }));
        } else {
          try {
            const data = await simpleFetchApiData(software);
            
            localStorage.setItem(software, JSON.stringify(data));
            localStorage.setItem(`${software}-timestamp`, currentTime.toString());

            setTechData(prev => ({ ...prev, [software]: data }));
          } catch (err) {
            setError(err.message);
          }
        }
      }
    });
  }, []);


  if (error) {
    return <div>Error: {error}</div>;}
    else 
    if (Object.keys(techData).length < landingPageSupportedTechs.length) {return <div>Loading...</div>;}

  return (
    <div className="home-selector-wrapper" style={{ padding: "8rem" }}>
      <form onSubmit={handleSubmit}>
        <div className="row-container">
            {landingPageSupportedTechs.slice(0, 2).map(tech => (
                <div key={tech} className="column">
                    <label className="m-1" htmlFor={`${tech}-versions`}>{tech}:</label>
                    <select
                        id={`${tech}-versions`}
                        ref={el => selectRefs.current[tech] = el}
                        className="form-control"
                    >
                        <option value="">Version</option>
                        {techData[tech]?.map(version => (
                            <option key={version.cycle} value={version.cycle}>
                                {version.cycle}
                            </option>
                        ))}
                        <option value="older">older</option>
                    </select>
                </div>
            ))}
        </div>
        <div className="row-container">
            {landingPageSupportedTechs.slice(2, 4).map(tech => (
                <div key={tech} className="column">
                    <label className="m-1" htmlFor={`${tech}-versions`}>{tech}:</label>
                    <select
                        id={`${tech}-versions`}
                        ref={el => selectRefs.current[tech] = el}
                        className="form-control"
                    >
                        <option value="">Version</option>
                        {techData[tech]?.map(version => (
                            <option key={version.cycle} value={version.cycle}>
                                {version.cycle}
                            </option>
                        ))}
                        <option value="older">older</option>
                    </select>
                </div>
            ))}
        </div>
        <div className="row-container">
            {landingPageSupportedTechs.slice(4, 6).map(tech => (
                <div key={tech} className="column">
                    <label className="m-1" htmlFor={`${tech}-versions`}>{tech}:</label>
                    <select
                        id={`${tech}-versions`}
                        ref={el => selectRefs.current[tech] = el}
                        className="form-control"
                    >
                        <option value="">Version</option>
                        {techData[tech]?.map(version => (
                            <option key={version.cycle} value={version.cycle}>
                                {version.cycle}
                            </option>
                        ))}
                        <option value="older">older</option>
                    </select>
                </div>
            ))}
        </div>
        <div className="row-container">
            {landingPageSupportedTechs.slice(6, 8).map(tech => (
                <div key={tech} className="column">
                    <label className="m-1" htmlFor={`${tech}-versions`}>{tech}:</label>
                    <select
                        id={`${tech}-versions`}
                        ref={el => selectRefs.current[tech] = el}
                        className="form-control"
                    >
                        <option value="">Version</option>
                        {techData[tech]?.map(version => (
                            <option key={version.cycle} value={version.cycle}>
                                {version.cycle}
                            </option>
                        ))}
                        <option value="older">older</option>
                    </select>
                </div>
            ))}
        </div>
        {error && <p className="error">{error}</p>}
        <div className="home-submit-wrapper my-4">
            <button className="btn landing-page-button" type="submit">Show Charts</button>
        </div>
    </form>
</div>

);
}

export default LandingPage;
