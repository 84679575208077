import React, { useContext } from "react"
import { capitalize } from "../../../utils"
import ChartContext from "../../../context/chartContextProvider/chartContextProvider"
import VersionSelector from "./Selectors/VersionSelector";
import './ChartHeader.css'

const ChartHeader = ({data, filteredData}) => {
  const { software } = useContext(ChartContext);

  return ( <>
            <div className='header-component'>
            <h2>{capitalize(software)} Timeline</h2>

            <div className='selector-wrapper'>
                <VersionSelector data={data}/>
              </div>
            </div>
          </>
  )
}

export default ChartHeader