const capitalize = text => {
  const words = text.replace(/-/g, ' ').split(' ');

  const capitalizedWords = words.map(word => 
    word[0].toUpperCase() + word.slice(1).toLowerCase()
  );

  return capitalizedWords.join(' ');
};

export default capitalize;
